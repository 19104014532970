




























import { Component, Vue, Prop } from 'vue-property-decorator';
import Hint from '@/components/Hint.vue'

@Component
export default class InputWrap extends Vue {
  @Prop() private label!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private isCheckbox?: boolean;
}
