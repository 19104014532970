
















































import { Component, Vue, Prop } from 'vue-property-decorator';
import InputWrap from '@/components/InputWrap.vue'
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
    InputWrap
  },
})
export default class BaseInputFile extends Vue {
  @Prop() private label!: string;
  @Prop() private hint!: string;

  $refs!: {
    provider: InstanceType<typeof ValidationProvider>;
  };

  private onChangeImage(e: any) {
    this.$emit('change', e);
    this.$refs.provider.validate(e.target.files[0]);
  }
}
